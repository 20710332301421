import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link } from "react-router-dom";
import { AppVersion } from "./Constants";

type Props = { defaultLeaderboard: boolean, defaultLeaderboardTemp: boolean, refreshSettingsModal: any, handleSettingsChange: any, handleSettingsSubmit: any, auth0: Auth0ContextInterface }
type State = { }
class Settings extends React.Component<Props, State> {
    componentDidMount() {
        this.registerModelShowHandler();
        this.registerModelCloseHandler();
    }

    componentWillUnmount() {
    }

    registerModelShowHandler() {
        var elem = document.getElementById('settingsModal');
        elem?.addEventListener('show.bs.modal', this.props.refreshSettingsModal)
    }

    registerModelCloseHandler() {
        var elem = document.getElementById('settingsModal');
        elem?.addEventListener('hidden.bs.modal', this.props.refreshSettingsModal)
    }

    render() {
        return (
            <div className="modal fade" id="settingsModal" tabIndex={-1} aria-labelledby="settingsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <form  onSubmit={this.props.handleSettingsSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="settingsModalLabel">Settings</h5>
                                <button type="button" className="btn-close" id="settingsModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="my-1">
                                    App Version: {AppVersion}
                                </div>
                                <div className="form-check form-switch">
                                    <span>Leaderboard Page by Default</span>
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.props.defaultLeaderboardTemp} onChange={this.props.handleSettingsChange} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                {this.props.auth0.isAuthenticated && (
                                    <Link to="/AdminSettings" className="btn btn-primary" onClick={() => {document.getElementById('settingsModalClose')?.click()}}>Admin Settings</Link>
                                )}
                                <button type="button" className="btn btn-success" onClick={this.props.handleSettingsSubmit}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth0(Settings);