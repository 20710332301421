import ChallengeModel from "./models/ChallengeModel";
import LeaderboardModel from "./models/LeaderboardModel";

export const AppVersion = "1.0.33";

export const ConnectWithStravaLink =
    "http://www.strava.com/oauth/authorize?client_id=70029&response_type=code&redirect_uri=https://darvelo30xthirtyfn.azurewebsites.net/api/Authentication/Code&approval_prompt=force&scope=read,activity:read,activity:write";

export const ChallengesJsonUri = "https://darvelo30xthirty.blob.core.windows.net/darvelo-files/challenges.json";

export const AzureFunctionUri = "https://darvelo30xthirtyfn.azurewebsites.net/";

export const BootstrapCardColors = ["primary", "success", "danger", "warning"];

export enum SortOrder {
    ThirtyXThirty = "ThirtyXThirty",
    Distance = "Distance",
    FirstName = "First Name",
    LastName = "Last Name",
    Time = "Time",
    Elevation = "Elevation",
    LongestDistance = "LongestDistance",
}

export function getCurrentChallenge(challenges: ChallengeModel[]) {
    sortChallengesByEndDate(challenges);

    if (challenges.length > 0) {
        return challenges[0];
    } else {
        return undefined;
    }
}

export function sortLeaderboardByQualifiers(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        if (a.is_qualified && b.is_qualified) {
            // if both users are qualified
            if (b.total_distance === a.total_distance) {
                return compareFirstThenLastName(a, b);
            }
            return b.total_distance - a.total_distance; // sort by distance
        } else if (a.is_qualified) {
            // if only a is qualified
            return -1;
        } else if (b.is_qualified) {
            // if only b is qualified
            return 1;
        }
        // if none are qualified
        var distance = b.total_distance - a.total_distance;
        if (distance) return distance; // sort by distance

        return compareFirstThenLastName(a, b); // sort by name
    });
}

export function sortLeaderboardByDistance(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var distance = b.total_distance - a.total_distance;
        if (distance) return distance;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByLongestDistance(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var distance = b.longest_distance - a.longest_distance;
        if (distance) return distance;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByElevation(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var elevation = b.total_elevation - a.total_elevation;
        if (elevation) return elevation;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByTime(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var time = b.total_time - a.total_time;
        if (time) return time;

        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByFirstName(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        return compareFirstThenLastName(a, b);
    });
}

export function sortLeaderboardByLastName(leaderboardModel: LeaderboardModel[]) {
    leaderboardModel.sort((a: LeaderboardModel, b: LeaderboardModel) => {
        var nameA = a.last_name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.last_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        var firstNameA = a.first_name.toUpperCase();
        var firstNameB = b.first_name.toUpperCase();
        if (firstNameA < firstNameB) {
            return -1;
        }
        if (firstNameA > firstNameB) {
            return 1;
        }
        return 0;
    });
}

export function sortChallengesByEndDate(challenges: ChallengeModel[]) {
    challenges.sort((a: ChallengeModel, b: ChallengeModel) => {
        // Turn strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return parseDate(b.end_date).valueOf() - parseDate(a.end_date).valueOf();
    });
}

function parseDate(dateStr: string): Date {
    var splitDate = dateStr.split("/");
    var year = parseInt(splitDate[2], 10);
    var month = parseInt(splitDate[1], 10);
    var day = parseInt(splitDate[0], 10);
    return new Date(year, month - 1, day);
}

function compareFirstThenLastName(a: LeaderboardModel, b: LeaderboardModel) {
    var nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    var lastNameA = a.last_name.toUpperCase();
    var lastNameB = b.last_name.toUpperCase();
    if (lastNameA < lastNameB) {
        return -1;
    }
    if (lastNameA > lastNameB) {
        return 1;
    }
    return 0;
}

export function filterOngoingChallenges(challenges: ChallengeModel[]) {
    return challenges.filter((c) => c.is_ongoing);
}
