import React from 'react';
import { ChallengesJsonUri, getCurrentChallenge, sortLeaderboardByDistance, sortLeaderboardByElevation, sortLeaderboardByFirstName, sortLeaderboardByLastName, sortLeaderboardByLongestDistance, sortLeaderboardByQualifiers, sortLeaderboardByTime, SortOrder } from './Constants';
import ChallengeModel, { ChallengeType } from './models/ChallengeModel';
import LeaderboardModel from './models/LeaderboardModel';
import ThirtyXThirtyMedal from './images/certificate.svg';
import GoldMedal from './images/gold-medal.svg';
import SilverMedal from './images/silver-medal.svg';
import BronzeMedal from './images/bronze-medal.svg';
import { useHistory, useLocation } from 'react-router-dom';
import {History, LocationState} from 'history';

type Props = { query: URLSearchParams, history: History<LocationState> };
type State = { loadingContent: boolean, currentView?: ChallengeModel, challenges?: ChallengeModel[], leaderboardModel?: LeaderboardModel[], failedFetch: boolean, sortBy: SortOrder };
class Leaderboard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingContent: true,
            currentView: undefined,
            challenges: undefined,
            leaderboardModel: undefined,
            failedFetch: false,
            sortBy: SortOrder.ThirtyXThirty
        }
    }

    componentDidMount() {
        this.getChallengesJson();
    }

    componentWillUnmount() { 
    }

    getChallengesJson() {
        fetch(ChallengesJsonUri, {cache: "no-cache"})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Leaderboard: ' + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then(data => {
                this.setState({challenges: data, failedFetch: false}, () => {
                    if (this.state.challenges)
                        if (this.props.query.has("id")) {
                            var id = this.props.query.get("id");
                            if (id) {
                                var challenge = this.state.challenges.find(c => c.id === id);
                                if (challenge) {
                                    this.setState({currentView: challenge}, () => {
                                        this.props.query.delete("id");
                                        this.props.history.push({search: this.props.query.toString()});
                                        this.updateCurrentView();
                                    });
                                } else {
                                    this.getCurrentChallengeAndUpdateView();
                                }
                            } else {
                                this.getCurrentChallengeAndUpdateView();
                            }
                        }
                        else {
                            this.getCurrentChallengeAndUpdateView();
                        }
                });
            })
            .catch((error) => {
                console.error('Error: ', error);
                this.setState({leaderboardModel: undefined, challenges: undefined, loadingContent: false, failedFetch: true});
            });
    }

    getCurrentChallengeAndUpdateView() {
        if (this.state.challenges) {
            this.setState({currentView: getCurrentChallenge(this.state.challenges)}, () => {
                this.updateCurrentView();
            });
        } else {
            this.setState({currentView: undefined, failedFetch: true});
        }
    }

    updateCurrentView() {
        if (!this.state.currentView || !this.state.currentView.leaderboard_link) {
            this.setState({loadingContent: false, failedFetch: true, leaderboardModel: undefined});
        } else {
            var sortOrder = this.state.sortBy;

            switch (this.state.currentView?.type) {
                case ChallengeType.Distance:
                case ChallengeType.Darvelo100:
                    sortOrder = SortOrder.Distance;
                    break;
                case ChallengeType.ThirtyXThirty:
                    sortOrder = SortOrder.ThirtyXThirty;
                    break;
                case ChallengeType.Elevation:
                    sortOrder = SortOrder.Elevation;
                    break;
                case ChallengeType.Time:
                    sortOrder = SortOrder.Time;
                    break;
                case ChallengeType.LongestDistance:
                    sortOrder = SortOrder.LongestDistance;
                    break;
            }

            this.setState({loadingContent: true, failedFetch: false, leaderboardModel: undefined, sortBy: sortOrder}, () => {
                if (this.state.currentView?.leaderboard_link) {
                    this.getChallengeLeaderboardJson(this.state.currentView.leaderboard_link);
                }
            })
        }
    }

    getChallengeLeaderboardJson(uri: string) {
        fetch(uri, {cache: "no-cache"})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Leaderboard: ' + response.statusText + "(" + response.status + ")");
                }
                return response.json()
            })
            .then(data => {
                this.setState({leaderboardModel: data, loadingContent: false, failedFetch: false}, () => {
                    this.sortLeaderboard();
                });
            })
            .catch((error) => {
                console.error('Error: ', error);
                this.setState({leaderboardModel: undefined, loadingContent: false, failedFetch: true});
            });
    }

    sortLeaderboard() {
        if(this.state.leaderboardModel) {
            switch (this.state.sortBy) {
                case SortOrder.ThirtyXThirty: 
                    sortLeaderboardByQualifiers(this.state.leaderboardModel);
                    break;
                case SortOrder.Distance:
                    sortLeaderboardByDistance(this.state.leaderboardModel);
                    break;
                case SortOrder.FirstName:
                    sortLeaderboardByFirstName(this.state.leaderboardModel);
                    break;
                case SortOrder.LastName:
                    sortLeaderboardByLastName(this.state.leaderboardModel);
                    break;
                case SortOrder.Elevation:
                    sortLeaderboardByElevation(this.state.leaderboardModel);
                    break;
                case SortOrder.Time:
                    sortLeaderboardByTime(this.state.leaderboardModel);
                    break;
                case SortOrder.LongestDistance:
                    sortLeaderboardByLongestDistance(this.state.leaderboardModel);
                    break;
            }
            this.setState({leaderboardModel: this.state.leaderboardModel});
        }
    }

    getChallengeTypeTitle() {
        var type = this.state.currentView?.type;
        switch (type) {
            case ChallengeType.Time:
                return <><div className="d-inline-flex">Time</div> <div className="d-inline-flex">(hh:mm:ss)</div></>;
            case ChallengeType.Elevation:
                return <><div className="d-inline-flex">Elevation</div> <div className="d-inline-flex">(m)</div></>;
            case ChallengeType.Darvelo100:
                if (this.state.sortBy === SortOrder.Time) return <><div className="d-inline-flex">Time</div> <div className="d-inline-flex">(hh:mm:ss)</div></>;
                return <><div className="d-inline-flex">Distance</div> <div className="d-inline-flex">(km)</div></>;
            case ChallengeType.Distance:
            case ChallengeType.ThirtyXThirty:
            case ChallengeType.LongestDistance:
            default:
                return <><div className="d-inline-flex">Distance</div> <div className="d-inline-flex">(km)</div></>;
        }
    }

    getChallengeTypeMetric() {
        var type = this.state.currentView?.type;
        switch (type) {
            case ChallengeType.Time:
                return "(hh:mm:ss)";
            case ChallengeType.Elevation:
                return "m";
            case ChallengeType.Darvelo100:
                if (this.state.sortBy === SortOrder.Time) return "(hh:mm:ss)";
                return "km";
            case ChallengeType.Distance:
            case ChallengeType.ThirtyXThirty:
            case ChallengeType.LongestDistance:
            default:
                return "km";
        }
    }

    getUserTotalForChallengeType(user: LeaderboardModel) {
        var type = this.state.currentView?.type;
        switch (type) {
            case ChallengeType.Time:
                return this.secondsToHms(user.total_time ? user.total_time : 0);
            case ChallengeType.Elevation:
                return this.convertTo2Decimals(user.total_elevation ? user.total_elevation : 0);
            case ChallengeType.LongestDistance:
                return this.convertTo2Decimals(user.longest_distance ? user.longest_distance : 0);
            case ChallengeType.Darvelo100:
                if (this.state.sortBy === SortOrder.Time) return this.secondsToHms(user.total_time ? user.total_time : 0);
                return this.convertTo2Decimals(user.total_distance ? user.total_distance : 0);
            case ChallengeType.Distance:
            case ChallengeType.ThirtyXThirty:
            default:
                return this.convertTo2Decimals(user.total_distance ? user.total_distance : 0);
        }
    }

    convertTo2Decimals (d: number) {
        return (Math.round(d * 100) / 100);
    }

    secondsToHms(d: number) {
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
    }

    getUserMedalComparisonValue(user: LeaderboardModel): number {
        if (this.state.currentView) {
            switch (this.state.currentView.type) {
                case ChallengeType.Time:
                    return user.total_time;
                case ChallengeType.Elevation:
                    return user.total_elevation;
                case ChallengeType.LongestDistance:
                    return user.longest_distance;
                case ChallengeType.Darvelo100:
                case ChallengeType.Distance:
                case ChallengeType.ThirtyXThirty:
                default:
                    return user.total_distance;
            }
        }
        return 0;
    }

    refreshChallengesJson() {
        this.setState({loadingContent: true, failedFetch: false}, () => {
            this.getChallengesJson();
        });
    }

    render () {
        return (
            <div>
                {this.state.currentView &&
                    <h1 className="pt-3">
                        Leaderboard for {this.state.currentView.name}
                    </h1>
                }
                {!this.state.currentView && 
                    <h1 className="pt-3">Leaderboard</h1>
                }
                {this.state.currentView?.show_medals && (
                    <div className="row d-flex">
                        <div className="d-inline-block">
                            <div id="KeyCollapse">
                                <div>
                                    <p className="h5 d-inline">
                                        Key: &nbsp;
                                    </p>
                                    <p className="d-inline">
                                        [
                                        <a className="" data-bs-toggle="collapse" href="#collapseKey" role="button" aria-expanded="false" aria-controls="collapseKey">
                                            Show/Hide
                                        </a>
                                        ]
                                    </p>
                                </div>
                                <div className="collapse" id="collapseKey">
                                    <div className="card card-body col-sm-12">
                                        <ul style={{listStyleType: 'none'}}>
                                            {this.state.currentView.type === ChallengeType.ThirtyXThirty && (
                                                <>
                                                    <li>
                                                        <span style={{paddingLeft: '30px', backgroundColor: '#f5c6cb'}}></span>
                                                        &nbsp; = You don't qualify for 30xThirty
                                                    </li>
                                                    <li>
                                                        <img src={ThirtyXThirtyMedal} width="30" height="30" title="30xThirty Medal" alt="30xThirty Medal"/>
                                                        &nbsp; = 30xThirty Medal (Ride 30km for 30 days)
                                                    </li>
                                                </>
                                            )}
                                            {this.state.currentView.type === ChallengeType.Darvelo100 && (
                                                <li>
                                                    <img src={ThirtyXThirtyMedal} width="30" height="30" title="Darvelo100 Medal" alt="Darvelo100 Medal"/>
                                                    &nbsp; = Darvelo100 Medal (Ride 100km or 100minutes)
                                                </li>
                                            )}
                                            {this.state.currentView.gold_medal > 0 && (
                                                <li>
                                                    <img src={GoldMedal} width="30" height="30" title="Gold Challenge Medal" alt="Gold Challenge Medal"/>
                                                    &nbsp; = Gold Challenge Medal ({
                                                        this.state.currentView.type === ChallengeType.Time ? this.secondsToHms(this.state.currentView.gold_medal) : this.state.currentView.gold_medal + this.getChallengeTypeMetric()
                                                    })
                                                </li>
                                            )}
                                            {this.state.currentView.silver_medal > 0 && (
                                                <li>
                                                    <img src={SilverMedal} width="30" height="30" title="Silver Challenge Medal" alt="Silver Challenge Medal"/>
                                                    &nbsp; = Silver Challenge Medal ({
                                                        this.state.currentView.type === ChallengeType.Time ? this.secondsToHms(this.state.currentView.silver_medal) : this.state.currentView.silver_medal + this.getChallengeTypeMetric()
                                                    })
                                                </li>
                                            )}
                                            {this.state.currentView.bronze_medal > 0 && (
                                                <li>
                                                    <img src={BronzeMedal} width="30" height="30" title="Bronze Challenge Medal" alt="Bronze Challenge Medal"/>
                                                    &nbsp; = Bronze Challenge Medal ({
                                                        this.state.currentView.type === ChallengeType.Time ? this.secondsToHms(this.state.currentView.bronze_medal) : this.state.currentView.bronze_medal + this.getChallengeTypeMetric()
                                                    })
                                                </li>
                                            )}
                                        </ul>
                                        <p className="text-muted">
                                            *Icons made by <a href="https://www.flaticon.com/authors/pixel-buddha" title="Pixel Buddha">Pixel Buddha</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <p className="text-muted">
                                *Qualifications are refreshed every night at 12:00 AM. <br />
                                *Activities from Strava may take upto 10 minutes to sync, please wait before reporting an issue!
                            </p>
                        </div>
                    </div>
                )}
                <div className="row" id="dropdown-content">
                    {this.state.challenges &&
                        <div className="d-inline-block float-end">
                            <div className="d-inline float-end mx-1">
                                <p className="d-inline">Sort by: </p>
                                <div className="dropdown d-inline">
                                    <button className="btn btn-outline-secondary dropdown-toggle my-1" type="button" data-bs-toggle="dropdown">
                                        {this.state.sortBy ? this.state.sortBy : "Sort by..."}
                                        <span className="caret"></span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        {this.state.currentView?.type === ChallengeType.ThirtyXThirty && (
                                            <li>
                                                <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.ThirtyXThirty) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.ThirtyXThirty}, () => {this.sortLeaderboard()})}}>ThirtyxThirty</button>
                                            </li>
                                        )}
                                        {(this.state.currentView?.type === ChallengeType.Time || this.state.currentView?.type === ChallengeType.Darvelo100) && (
                                            <li>
                                                <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.Time) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.Time}, () => {this.sortLeaderboard()})}}>Time</button>
                                            </li>
                                        )}
                                        {this.state.currentView?.type === ChallengeType.Elevation && (
                                            <li>
                                                <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.Elevation) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.Elevation}, () => {this.sortLeaderboard()})}}>Elevation</button>
                                            </li>
                                        )}
                                        {this.state.currentView?.type === ChallengeType.LongestDistance && (
                                            <li>
                                                <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.LongestDistance) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.LongestDistance}, () => {this.sortLeaderboard()})}}>Longest Distance</button>
                                            </li>
                                        )}
                                        {(this.state.currentView?.type === ChallengeType.ThirtyXThirty || this.state.currentView?.type === ChallengeType.Distance || this.state.currentView?.type === ChallengeType.Darvelo100) && (
                                            <li>
                                                <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.Distance) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.Distance}, () => {this.sortLeaderboard()})}}>Distance</button>
                                            </li>
                                        )}
                                        {this.state.currentView?.type !== ChallengeType.Darvelo100 && (
                                            <>
                                                <li>
                                                    <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.FirstName) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.FirstName}, () => {this.sortLeaderboard()})}}>First Name</button>
                                                </li>
                                                <li>
                                                    <button className={"dropdown-item" + ((this.state.sortBy === SortOrder.LastName) ? " active" : "")} onClick={() => {this.setState({sortBy: SortOrder.LastName}, () => {this.sortLeaderboard()})}}>Last Name</button>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="d-inline float-end mx-1">
                                <p className="d-inline">View: </p>
                                <div className="dropdown d-inline">
                                    <button className="btn btn-outline-secondary dropdown-toggle my-1" type="button" data-bs-toggle="dropdown">
                                        {this.state.currentView ? this.state.currentView.name : "View..."}
                                        <span className="caret"></span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        {this.state.challenges && this.state.challenges.map((challenge: ChallengeModel, index: number) => {
                                            return (
                                                <li key={index}>
                                                    <button className={"dropdown-item" + ((this.state.currentView?.id === challenge.id) ? " active" : "")} onClick={() => {this.setState({currentView: challenge}, () => {this.updateCurrentView()})}}>{challenge.name}</button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="row m-2" id="loading-spinner">
                    {this.state.loadingContent &&
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="row m-2" id="refresh-button">
                    {this.state.failedFetch && !this.state.loadingContent &&
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary btn-lg" onClick={() => this.refreshChallengesJson()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16" style={{marginRight: 10}}>
                                    <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"></path>
                                    <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"></path>
                                </svg>
                                Refresh
                            </button>
                        </div>
                    }
                </div>
                <div className="row" id="main-content">
                    {this.state.leaderboardModel && 
                        <table className="table">
                            <thead>
                                <tr className="">
                                    <th className="col-md-1">
                                        #
                                    </th>
                                    <th className="col-md-5">
                                        Name
                                    </th>
                                    <th className="col-md-3">
                                        {this.getChallengeTypeTitle()}
                                    </th>
                                    {this.state.currentView?.show_medals && (
                                        <th className="col-md-3">
                                            Medals
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.leaderboardModel.map((user: LeaderboardModel, index: number) => {
                                    return (
                                        <tr className={"" + (user.is_qualified ? "" : " table-danger")} key={index}>
                                            <td className="col-md-1">
                                                <a className="link-primary" href={"https://www.strava.com/athletes/" + user.id}>
                                                    {index + 1}
                                                </a>
                                            </td>
                                            <td className="col-md-5">
                                                {user.first_name + " " + user.last_name}
                                            </td>
                                            <td className="col-md-3">
                                                {this.getUserTotalForChallengeType(user)}
                                            </td>
                                            {this.state.currentView?.show_medals && (
                                                <td className="col-md-3">
                                                    {this.state.currentView.type === ChallengeType.ThirtyXThirty && user.challenge_completed &&
                                                        <img src={ThirtyXThirtyMedal} width="30" height="30" title="30xThirty Medal" alt="30xThirty Medal" />
                                                    }
                                                    {this.state.currentView.type === ChallengeType.Darvelo100 && user.challenge_completed && (
                                                        <img src={ThirtyXThirtyMedal} width="30" height="30" title="Darvelo100 Medal" alt="Darvelo100 Medal" />
                                                    )}
                                                    {this.state.currentView.gold_medal > 0 && this.getUserMedalComparisonValue(user) >= this.state.currentView.gold_medal &&
                                                        <img src={GoldMedal} width="30" height="30" title="Gold Challenge Medal" alt="Gold Challenge Medal" />
                                                    }
                                                    {this.state.currentView.silver_medal > 0 && this.getUserMedalComparisonValue(user) >= this.state.currentView.silver_medal &&
                                                        <img src={SilverMedal} width="30" height="30" title="Silver Challenge Medal" alt="Silver Challenge Medal" />
                                                    }
                                                    {this.state.currentView.bronze_medal > 0 && this.getUserMedalComparisonValue(user) >= this.state.currentView.bronze_medal &&
                                                        <img src={BronzeMedal} width="30" height="30" title="Bronze Challenge Medal" alt="Bronze Challenge Medal" />
                                                    }
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        );
    }
}

export default injectQuery(Leaderboard);

function injectQuery (Comp: typeof React.Component) {
    const InjectedQuery = function (props: any) {
        const query = useQuery();
        const history = useHistory();
        return <Comp {...props} query={query} history={history} />;
    };
    return InjectedQuery;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}