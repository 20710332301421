interface ChallengeModel {
    id: string;
    name: string;
    start_date: string;
    end_date: string;
    type: ChallengeType;
    is_ongoing: boolean;
    leaderboard_link: string;
    show_medals: boolean;
    gold_medal: number;
    silver_medal: number;
    bronze_medal: number;
    description: string;
}

export default ChallengeModel;

export enum ChallengeType {
    ThirtyXThirty = "ThirtyXThirty",
    Distance = "Distance",
    LongestDistance = "LongestDistance",
    Time = "Time",
    Elevation = "Elevation",
    Darvelo100 = "Darvelo100",
}