import React from "react";

class ReloadNotification extends React.Component {
    render() {
        return (
            <div className="position-fixed bottom-0 end-0 p-3" style={{zIndex: 11}}>
                <div className="toast align-items-center text-white bg-success border-0 fade hide" id="notificationToast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide={false}>
                    <div className="d-flex">
                        <div className="toast-body">
                            A new version of this app is available. <br />
                            <button id="reload-page" className="btn btn-link text-white m-0 p-0 align-baseline" style={{fontSize: "inherit"}}>Click here</button> to refresh the app.
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReloadNotification;