import { Link, useLocation } from "react-router-dom";

const ErrorPage = ({message} : { message?:string }) => {
    let query = useQuery();
    let error = message ?? query.get("error");
    
    const errorType = error?.startsWith("Warning") ? "warning" : "danger";

    error = error?.startsWith("Error - ") ? error.replace("Error - ", "") : error;
    error = error?.startsWith("Warning - ") ? error.replace("Warning - ", "") : error;

    return (
        <div className="container">
            <h1 className="display-4">Error</h1>
            <svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>
                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </symbol>
            </svg>
            {error &&
                <div className={"alert d-flex align-items-center alert-" + errorType} role="alert">
                    <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill"/></svg>
                    <div>
                        {error}
                    </div>
                </div>
            }
            <div className="d-flex flex-row-reverse">
                <Link to="/" className="btn btn-primary">Go Back</Link>
            </div>
        </div>
    );
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default ErrorPage;