import { withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { AzureFunctionUri } from "./Constants";
import ChallengeModel from "./models/ChallengeModel";

type Props = { auth0:any }
type State = { loadingContent: boolean, challenges?: ChallengeModel[], failedFetch: boolean, viewingChallenge?: ChallengeModel }
class Challenges extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingContent: true,
            challenges: undefined,
            failedFetch: false,
            viewingChallenge: undefined
        }

        this.redirectToEdit = this.redirectToEdit.bind(this);
        this.resetViewingChallengeModal = this.resetViewingChallengeModal.bind(this);
    }
    
    componentDidMount() {
        if (this.props.auth0.isAuthenticated) {
            this.getChallenges();
        }

        this.registerModelCloseHandler();
    }

    componentWillUnmount() { 
    }

    componentDidUpdate(prevProps: Props) {
        if(this.state.loadingContent && prevProps.auth0.isLoading && !this.props.auth0.isLoading) {
            if (this.props.auth0.isAuthenticated) {
                this.getChallenges();
            }
        }
    }

    async getChallenges() {
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/Challenges", {
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Challenges: ' + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then(data => {
                this.setState({challenges: data, loadingContent: false, failedFetch: false});
            })
            .catch((error) => {
                console.error('Error: ', error);
                this.setState({challenges: undefined, loadingContent: false, failedFetch: true});
            });
    }

    async deleteChallenge() {
        if (this.state.viewingChallenge) {
            const accessToken = await this.props.auth0.getAccessTokenSilently();
            fetch(AzureFunctionUri + "api/Challenges/" + this.state.viewingChallenge.id, {
                method: "DELETE",
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Challenges (DELETE): ' + response.statusText + "(" + response.status + ")");
                }
                this.getChallenges();

                var challengeModal = document.getElementById('challengesModalClose');
                challengeModal?.click();

                var deleteChallengeModal = document.getElementById('deleteChallengeModalClose');
                deleteChallengeModal?.click();
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    refreshChallenges() {
        this.setState({loadingContent: true, failedFetch: false}, () => {
            this.getChallenges();
        });
    }

    updateModal() {
        var id = "", name = "", startDate = "", endDate = "", type = "", ongoing = "", showMedals = "", goldMedal = 0, silverMedal = 0, bronzeMedal = 0, description = "";
        if (this.state.viewingChallenge) {
            id = this.state.viewingChallenge.id ?? "";
            name = this.state.viewingChallenge.name ?? "";
            startDate = this.state.viewingChallenge.start_date ?? "";
            endDate = this.state.viewingChallenge.end_date ?? "";
            type = this.state.viewingChallenge.type ?? "";
            ongoing = this.state.viewingChallenge.is_ongoing ? "true" : "false";
            showMedals = this.state.viewingChallenge.show_medals ? "true" : "false";
            goldMedal = this.state.viewingChallenge.gold_medal;
            silverMedal = this.state.viewingChallenge.silver_medal;
            bronzeMedal = this.state.viewingChallenge.bronze_medal;
            description = this.state.viewingChallenge.description ?? "";
        }
        var challengeId = document.getElementById('modal-challenge-id');
        if (challengeId)
            challengeId.innerHTML = id;
            
        var challengeName = document.getElementById('modal-challenge-name');
        if (challengeName)
            challengeName.innerHTML = name;
        
        var challengeStartDate = document.getElementById('modal-challenge-start-date');
        if (challengeStartDate)
            challengeStartDate.innerHTML = startDate;
        
        var challengeEndDate = document.getElementById('modal-challenge-end-date');
        if (challengeEndDate)
            challengeEndDate.innerHTML = endDate;

        var challengeType = document.getElementById('modal-challenge-type');
        if (challengeType)
            challengeType.innerHTML = type;

        var challengeOngoing = document.getElementById('modal-challenge-ongoing');
        if (challengeOngoing)
            challengeOngoing.innerHTML = ongoing;

        var challengeShowMedals = document.getElementById('modal-challenge-show-medals');
        if (challengeShowMedals)
            challengeShowMedals.innerHTML = showMedals;

        var challengeGoldMedal = document.getElementById('modal-challenge-gold-medal');
        if (challengeGoldMedal)
            challengeGoldMedal.innerHTML = goldMedal.toString();

        var challengeSilverMedal = document.getElementById('modal-challenge-silver-medal');
        if (challengeSilverMedal)
            challengeSilverMedal.innerHTML = silverMedal.toString();

        var challengeBronzeMedal = document.getElementById('modal-challenge-bronze-medal');
        if (challengeBronzeMedal)
            challengeBronzeMedal.innerHTML = bronzeMedal.toString();

        var challengeDescription = document.getElementById('modal-challenge-description');
        if (challengeDescription) 
            challengeDescription.innerText = description.toString();
    }

    registerModelCloseHandler() {
        var elem = document.getElementById('challengesModal');
        elem?.addEventListener('hidden.bs.modal', this.resetViewingChallengeModal)
    }

    resetViewingChallengeModal(event:any) {
        this.setState({viewingChallenge: undefined}, () => {this.updateModal()});
    }

    redirectToEdit(event: any) {
        event.preventDefault();

        var id = this.state.viewingChallenge?.id;
        
        var modal = document.getElementById('challengesModal');
        modal?.removeEventListener('hidden.bs.modal', this.resetViewingChallengeModal);
        modal?.addEventListener('hidden.bs.modal', (event:any) => {
            if (id) {
                var editButton = document.getElementById("challengeEdit_" + id);
                editButton?.click();
            }
        })

        var modalClose = document.getElementById("challengesModalClose");
        if (modalClose) modalClose.click();
    }

    render() {
        return (
            <div>
                {!this.props.auth0.isAuthenticated && !this.props.auth0.isLoading && (
                    <Redirect to="/Error?error=You are not authorized to view this page. Please log in." />
                )}
                <div className="pt-2">
                    <h1 className="d-inline">Challenges</h1>
                    {this.props.auth0.isAuthenticated && (
                        <div className="d-inline float-end mx-2">
                            <Link to="/Challenges/Create" className="btn btn-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-circle align-middle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                            </Link>
                        </div>)}
                </div>
                {this.props.auth0.isAuthenticated && (
                    <>
                        <div className="row m-2" id="loading-spinner">
                            {this.state.loadingContent &&
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row m-2" id="refresh-button">
                            {this.state.failedFetch && !this.state.loadingContent &&
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-primary btn-lg" onClick={() => this.refreshChallenges()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16" style={{marginRight: 10}}>
                                            <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"></path>
                                            <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"></path>
                                        </svg>
                                        Refresh
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="row" id="main-content">
                            {this.state.challenges && (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.challenges.map((challenge: ChallengeModel, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="align-middle">{challenge.name}</td>
                                                    <td>
                                                        <Link to={"/Challenges/Edit?id=" + challenge.id} className="btn btn-primary float-end mx-1" id={"challengeEdit_" + challenge.id}>Edit</Link>
                                                        <button className="btn btn-secondary float-end mx-1" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#challengesModal" 
                                                            onClick={() => {this.setState({viewingChallenge: challenge}, () => {this.updateModal()})}}>
                                                                Info
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table> 
                            )}
                        </div>
                    </>
                )}
                <div className="modal fade" id="challengesModal" tabIndex={-1} aria-labelledby="challengesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <form>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="challengesModalLabel">Challenge Info</h5>
                                    <button type="button" className="btn-close" id="challengesModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div><b>Id:</b> <span id="modal-challenge-id"></span></div>
                                    <div><b>Name:</b> <span id="modal-challenge-name"></span></div>
                                    <div><b>Start Date:</b> <span id="modal-challenge-start-date"></span></div>
                                    <div><b>End Date:</b> <span id="modal-challenge-end-date"></span></div>
                                    <div><b>Type:</b> <span id="modal-challenge-type"></span></div>
                                    <div><b>Is Ongoing:</b> <span id="modal-challenge-ongoing"></span></div>
                                    <div><b>Show Medals:</b> <span id="modal-challenge-show-medals"></span></div>
                                    <div><b>Gold Medal:</b> <span id="modal-challenge-gold-medal"></span></div>
                                    <div><b>Silver Medal:</b> <span id="modal-challenge-silver-medal"></span></div>
                                    <div><b>Bronze Medal:</b> <span id="modal-challenge-bronze-medal"></span></div>
                                    <div><b>Description:</b> <br/> <span id="modal-challenge-description"></span></div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.redirectToEdit}  className="btn btn-primary float-end mx-1">Edit</button>
                                    <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteChallengeModal">Delete</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteChallengeModal" tabIndex={-1} aria-labelledby="deleteChallengeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <form>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteChallengeModalLabel">Delete Challenge</h5>
                                    <button type="button" className="btn-close" id="deleteChallengeModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure you want to delete challenge '{this.state.viewingChallenge?.name}' with id '{this.state.viewingChallenge?.id}'?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-danger" onClick={() => {this.deleteChallenge()}}>Delete</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth0(Challenges);