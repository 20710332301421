import { withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Redirect } from "react-router-dom";
import { AzureFunctionUri } from "./Constants";
import ChallengeModel from "./models/ChallengeModel";
import UserModel from "./models/UserModel";

type Props = { auth0: any }
type State = { loadingContent: boolean, users?: UserModel[], failedFetch: boolean, selectedUser?: UserModel, challenges?: ChallengeModel[], selectedChallenge?: ChallengeModel }
class Users extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingContent: true,
            failedFetch: false,
            users: undefined,
            selectedUser: undefined,
            challenges: undefined,
            selectedChallenge: undefined
        }

        this.handleChallengeSelect = this.handleChallengeSelect.bind(this);
    }

    componentDidMount() {
        if (this.props.auth0.isAuthenticated) {
            this.getUsers();
            this.getChallenges();
        }

        this.registerUserInfoModelCloseHandler();
    }

    componentWillUnmount() { 
    }

    componentDidUpdate(prevProps: Props) {
        if(this.state.loadingContent && prevProps.auth0.isLoading && !this.props.auth0.isLoading) {
            if (this.props.auth0.isAuthenticated) {
                this.getUsers();
                this.getChallenges();
            }
        }
    }

    async getUsers() {
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/Users", {
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Users (GET): ' + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then(data => {
                this.setState({users: data, loadingContent: false, failedFetch: false});
            })
            .catch((error) => {
                console.error('Error: ' + error);
                this.setState({users: undefined, loadingContent: false, failedFetch: true});
            });
    }

    async deleteUser() {
        if (this.state.selectedUser) {
            const accessToken = await this.props.auth0.getAccessTokenSilently();
            fetch(AzureFunctionUri + "api/Users/" + this.state.selectedUser.id, {
                method: "DELETE",
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Users (DELETE): ' + response.statusText + "(" + response.status + ")");
                }
                this.getUsers();

                var deleteUserModal = document.getElementById('deleteUserModalClose');
                deleteUserModal?.click();

                var userInfoClose = document.getElementById("userInfoModalClose");
                userInfoClose?.click();
            }).catch((error) => {
                console.error('Error: ' + error);
            });
        }
    }

    async getChallenges() {
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/Challenges", {
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Challenges: ' + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then(data => {
                var challenges = data.filter((c: ChallengeModel) => {
                    return c.is_ongoing;
                });
                this.setState({challenges: challenges});
            })
            .catch((error) => {
                console.error('Error: ' + error);
                this.setState({challenges: undefined});
            });
    }

    async backtrackUser() {
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        if (this.state.selectedUser && this.state.selectedChallenge) {
            fetch(AzureFunctionUri + "api/Users/" + this.state.selectedUser.id + "/Backtrack/" + this.state.selectedChallenge.id, {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. BacktrackUser (PUT): ' + response.statusText + "(" + response.status + ")");
                }

                var userInfoClose = document.getElementById("userInfoModalClose");
                userInfoClose?.click();

                this.setState({selectedUser: undefined, selectedChallenge: undefined});
            })
            .catch((error) => {
                console.log('Error: ' + error);
                var userInfoClose = document.getElementById("userInfoModalClose");
                userInfoClose?.click();

                this.setState({selectedUser: undefined, selectedChallenge: undefined});
            })
        }
    }

    refreshUsers() {
        this.setState({loadingContent: true, failedFetch: false}, () => {
            this.getUsers();
        });
    }

    registerUserInfoModelCloseHandler() {
        var elem = document.getElementById('userInfoModal');
        elem?.addEventListener('hidden.bs.modal', (event:any) => {
            this.setState({selectedUser: undefined, selectedChallenge: undefined});
        })
    }

    handleChallengeSelect(event: any) {
        var challenge = this.state.challenges?.find(c => c.id === event.target.value);
        if (challenge) {
            this.setState({selectedChallenge: challenge});
        }
        else {
            this.setState({selectedChallenge: undefined});
        }
    }

    render() {
        return (
            <div>
                {!this.props.auth0.isAuthenticated && !this.props.auth0.isLoading && (
                    <Redirect to="/Error?error=You are not authorized to view this page. Please log in." />
                )}
                <h1 className="pt-2">Users</h1>
                {this.props.auth0.isAuthenticated && (
                    <>
                        <div className="row m-2" id="loading-spinner">
                            {this.state.loadingContent &&
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row m-2" id="refresh-button">
                            {this.state.failedFetch && !this.state.loadingContent &&
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-primary btn-lg" onClick={() => this.refreshUsers()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16" style={{marginRight: 10}}>
                                            <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z"></path>
                                            <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z"></path>
                                        </svg>
                                        Refresh
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="row" id="main-content">
                            {this.state.users && (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users.map((user: UserModel, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <a className="link-primary" href={"https://www.strava.com/athletes/" + user.id}>
                                                            {index + 1}
                                                        </a>
                                                    </td>
                                                    <td className="align-middle">{user.first_name + " " + user.last_name}</td>
                                                    <td>
                                                        <button className="btn btn-secondary float-end" data-bs-toggle="modal" data-bs-target="#userInfoModal" onClick={() => {this.setState({selectedUser: user})}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill align-middle" viewBox="0 0 16 16">
                                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table> 
                            )}
                        </div>
                    </>
                )}
                <div className="modal fade" id="userInfoModal" tabIndex={-1} aria-labelledby="userInfoModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="userInfoModalLabel">User Info</h5>
                            <button type="button" className="btn-close" id="userInfoModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Strava: <a href={"https://www.strava.com/athletes/" + this.state.selectedUser?.id}>View Profile</a> <br />
                            Name: {this.state.selectedUser?.first_name + " " + this.state.selectedUser?.last_name} <br />
                            Activity Updates: {this.state.selectedUser?.update_activities ? "Enabled" : "Disabled"} <br />
                            <br />
                            <h5>Backtrack Activities</h5>
                            {this.state.challenges && this.state.challenges.length > 0 && (
                                <>
                                <select className="form-select my-1" aria-label="Select Challenge" id="challengeSelect" name="challenge" value={this.state.selectedChallenge ? this.state.selectedChallenge.id : "select"} onChange={this.handleChallengeSelect}>
                                    <option value="select">Select...</option>
                                    {this.state.challenges.map((c: ChallengeModel, index: number) => {
                                        return (<option key={index} value={c.id}>{c.name}</option>);
                                    })}
                                </select>
                                <button className="btn btn-primary" disabled={this.state.selectedChallenge ? false : true} onClick={() => {this.backtrackUser()}}>Backtrack</button>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger" 
                                data-bs-toggle="modal" 
                                data-bs-target="#deleteUserModal">
                                    Delete
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteUserModal" tabIndex={-1} aria-labelledby="deleteUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <form>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteUserModalLabel">Delete User</h5>
                                    <button type="button" className="btn-close" id="deleteUserModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure you want to delete user '{this.state.selectedUser?.first_name + " " + this.state.selectedUser?.last_name}' with id '{this.state.selectedUser?.id}'?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-danger" onClick={() => {this.deleteUser()}}>Delete</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuth0(Users);