import { useAuth0 } from "@auth0/auth0-react";

const AuthButtons = () => {
    const {loginWithRedirect, logout, user, isLoading} = useAuth0();

    return (
        <li className="nav-item d-grid">
            {!isLoading && !user && (
                <button className="nav-link text-white btn btn-primary mx-md-1 ml-md-2 mt-1 mt-md-0" onClick={() => loginWithRedirect()}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: 10}} width="16" height="16" fill="currentColor" className="bi bi-person-fill align-middle" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                    <span className="align-middle" style={{marginLeft: 10, marginRight: 10}}>Log In</span>
                </button>
            )}
            {!isLoading && user && (
                <button className="nav-link text-white btn btn-primary mx-md-1 ml-md-2 mt-1 mt-md-0" onClick={() => logout({returnTo: window.location.origin})}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: 10}} width="16" height="16" fill="currentColor" className="bi bi-person-x-fill align-middle" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    <span className="align-middle" style={{marginLeft: 10, marginRight: 10}}>Log Out</span>
                </button>
            )}
        </li>
    );
}

export default AuthButtons;