import { withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { AzureFunctionUri } from "./Constants";
import ChallengeModel, { ChallengeType } from "./models/ChallengeModel";

// Date Picker
import TextField from "@material-ui/core/TextField";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

type Props = { auth0: any, query: any }
type State = { viewingChallenge?: ChallengeModel, loadingContent: boolean, failedFetch: boolean, edited:boolean }
class EditChallenge extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            viewingChallenge: undefined,
            loadingContent: true,
            failedFetch: false,
            edited: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        if (this.props.auth0.isAuthenticated) {
            this.getChallenge();
        }
    }

    componentWillUnmount() { 
    }

    componentDidUpdate(prevProps: Props) {
        if(this.state.loadingContent && prevProps.auth0.isLoading && !this.props.auth0.isLoading) {
            if (this.props.auth0.isAuthenticated) {
                this.getChallenge();
            }
        }
    }

    async getChallenge() {
        var id = this.props.query.get("id");
        var accessToken = await this.props.auth0.getAccessTokenSilently();
        if (id && accessToken) {
            fetch(AzureFunctionUri + "api/Challenges/" + id, {
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. ChallengeById (GET): ' + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then(data => {
                this.setState({viewingChallenge: data, loadingContent: false, failedFetch: false});
            })
            .catch((error) => {
                console.error('Error: ', error);
                this.setState({viewingChallenge: undefined, loadingContent: false, failedFetch: true});
            });;

        }
    }

    async editChallenge() {
        var accessToken = await this.props.auth0.getAccessTokenSilently();
        if(accessToken && this.state.viewingChallenge && this.state.viewingChallenge.name) {
            fetch(AzureFunctionUri + "api/Challenges/" + this.state.viewingChallenge.id, {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.viewingChallenge)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Challenges (PUT): ' + response.statusText + "(" + response.status + ")");
                }
                this.setState({edited: true});
            }).catch((error) => {
                console.error(error);
                this.setState({edited: true});
            });
        }
    }

    handleInputChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var challenge: any = this.state.viewingChallenge;
        if (challenge) {
            challenge[name] = value;
            this.setState({viewingChallenge: challenge});
        }
    }

    handleBlur(event: any) {
        if (event.target.type === 'number' && !event.target.value) {
            var challenge: any = this.state.viewingChallenge;
            if (challenge) {
                challenge[event.target.name] = 0;
                this.setState({viewingChallenge: challenge});
            }
        }
    }

    parseDate(dateStr: string) {
        var splitDate = dateStr.split('/');
        var year = parseInt(splitDate[2], 10);
        var month = parseInt(splitDate[1], 10);
        var day = parseInt(splitDate[0], 10);
        return new Date(year, month - 1, day);
    }

    render() {
        return (
            <div className="container">
                {!this.props.auth0.isAuthenticated && !this.props.auth0.isLoading && (
                    <Redirect to="/Error?error=You are not authorized to view this page. Please log in." />
                )}
                {this.state.edited && (
                    <Redirect to="/Challenges" />
                )}
                <h1 className="pt-3">Edit Challenge</h1>
                {this.props.auth0.isAuthenticated && this.state.viewingChallenge && (
                    <>
                        <form>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeIdInput"  className="col-4 col-sm-3 col-lg-2">Id: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input type="text" className="form-control" id="challengeIdInput" placeholder="Challenge Id" value={this.state.viewingChallenge.id} name="id" disabled/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeNameInput"  className="col-4 col-sm-3 col-lg-2">Name: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input type="text" className="form-control" id="challengeNameInput" placeholder="Challenge Name" value={this.state.viewingChallenge.name} name="name" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeStartDateInput" className="col-4 col-sm-3 col-lg-2">Start Date: </label>
                                <div className="col-8 col-md-6 col-lg-4 my-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={this.parseDate(this.state.viewingChallenge.start_date)}
                                            onChange={(date: Date | null) => {
                                                var old = this.state.viewingChallenge;
                                                if (old)
                                                    old.start_date = date ? date.toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB');
                                                    this.setState({viewingChallenge: old});
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} />}
                                            disableCloseOnSelect={false}
                                            inputFormat="dd/MM/yyyy"
                                            InputAdornmentProps={{ position: "start" }}
                                            maxDate={this.parseDate(this.state.viewingChallenge.end_date)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeEndDateInput" className="col-4 col-sm-3 col-lg-2">End Date: </label>
                                <div className="col-8 col-md-6 col-lg-4 my-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={this.parseDate(this.state.viewingChallenge.end_date)}
                                            onChange={(date: Date | null) => {
                                                var old = this.state.viewingChallenge;
                                                if (old)
                                                    old.end_date = date ? date.toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB');
                                                    this.setState({viewingChallenge: old});
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} />}
                                            disableCloseOnSelect={false}
                                            inputFormat="dd/MM/yyyy"
                                            InputAdornmentProps={{ position: "start" }}
                                            minDate={this.parseDate(this.state.viewingChallenge.start_date)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <label className="col-4 col-sm-3 col-lg-2" htmlFor="challengeTypeSelect">
                                    Type: 
                                </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <select className="form-select" aria-label="Select Challenge Type" id="challengeTypeSelect" name="type" value={this.state.viewingChallenge.type} onChange={this.handleInputChange}>
                                        <option value={ChallengeType.Distance}>{ChallengeType.Distance}</option>
                                        <option value={ChallengeType.ThirtyXThirty}>{ChallengeType.ThirtyXThirty}</option>
                                        <option value={ChallengeType.LongestDistance}>{ChallengeType.LongestDistance}</option>
                                        <option value={ChallengeType.Elevation}>{ChallengeType.Elevation}</option>
                                        <option value={ChallengeType.Time}>{ChallengeType.Time}</option>
                                        <option value={ChallengeType.Darvelo100}>{ChallengeType.Darvelo100}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row my-2 align-items-center">
                                <div className="col-4 col-sm-3 col-lg-2">Is Ongoing? </div>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-check-input" type="checkbox" checked={this.state.viewingChallenge.is_ongoing} id="challengeOngoingCheck" name="is_ongoing" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="row my-2 align-items-center">
                                <div className="col-4 col-sm-3 col-lg-2">Show Medals? </div>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-check-input" type="checkbox" checked={this.state.viewingChallenge.show_medals} id="challengeShowMedals" name="show_medals" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeGoldMedal" className="col-4 col-sm-3 col-lg-2">Gold Medal: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-control" type="number" min={0} id="challengeGoldMedal" name="gold_medal" aria-describedby="challengeGoldMedal" value={this.state.viewingChallenge.gold_medal} onChange={this.handleInputChange} onBlur={this.handleBlur} disabled={!this.state.viewingChallenge.show_medals}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeSilverMedal" className="col-4 col-sm-3 col-lg-2">Silver Medal: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-control" type="number" min={0} id="challengeSilverMedal" name="silver_medal" aria-describedby="challengeSilverMedal" value={this.state.viewingChallenge.silver_medal} onChange={this.handleInputChange} onBlur={this.handleBlur} disabled={!this.state.viewingChallenge.show_medals}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeBronzeMedal" className="col-4 col-sm-3 col-lg-2">Bronze Medal: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-control" type="number" min={0} id="challengeBronzeMedal" name="bronze_medal" aria-describedby="challengeBronzeMedal" value={this.state.viewingChallenge.bronze_medal} onChange={this.handleInputChange} onBlur={this.handleBlur} disabled={!this.state.viewingChallenge.show_medals}/>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="challengeDescription" className="form-label">Challenge Description</label>
                                <textarea className="form-control" id="challengeDescription" name="description" aria-describedby="challengeDescription" value={this.state.viewingChallenge.description} onChange={this.handleInputChange} rows={3}></textarea>
                            </div>
                        </form>
                        <button className="btn btn-success my-2" onClick={() => {this.editChallenge()}}>Edit</button>
                    </>
                )}
                <div className="d-flex flex-row-reverse">
                    <Link to="/Challenges" className="btn btn-primary">Go Back</Link>
                </div>
            </div>
        );
    }
}

export default withAuth0(injectQuery(EditChallenge));

function injectQuery (Comp: typeof React.Component) {
    const InjectedQuery = function (props: any) {
        const query = useQuery();
        return <Comp {...props} query={query} />;
    };
    return InjectedQuery;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}