import React from 'react';
import DarveloBanner from './images/med-banner.png';

class ContentContainer extends React.Component {
    render() {
        return (
            <div className="container">
                <main role="main" className="pb-3">
                    <div className="col px-0">
                        <img src={DarveloBanner} className="img-fluid d-block" width={"100%"} alt="Darvelo Banner"/>
                    </div>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

export default ContentContainer;