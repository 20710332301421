import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminSettings from './AdminSettings';
import Auth0ProviderWithHistory from './Auth0Provider';
import Challenges from './Challenges';
import ContentContainer from './ContentContainer';
import CreateChallenge from './CreateChallenge';
import EditChallenge from './EditChallenge';
import ErrorPage from './ErrorPage';
import Footer from './Footer';
import Home from './Home';
import Leaderboard from './Leaderboard';
import Navbar from './NavBar';
import ReloadNotification from './ReloadNotification';
import Settings from './Settings';
import StatusMessage from './StatusMessage';
import Users from './Users';

type Props = {};
type State = { defaultLeaderboard: boolean, defaultLeaderboardTemp: boolean };
class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        var defaultLeaderboard = false;
        if (localStorage.getItem('defaultLeaderboard')) {
            defaultLeaderboard = localStorage.getItem('defaultLeaderboard') === 'true';
        }

        this.state = {
            defaultLeaderboard: defaultLeaderboard,
            defaultLeaderboardTemp: defaultLeaderboard
        }
    }

    handleSettingsChange(event: any) {
        this.setState({
            defaultLeaderboardTemp: event.currentTarget.checked
        });
    }

    handleSettingsSubmit(event: any) {
        if(this.state.defaultLeaderboard !== this.state.defaultLeaderboardTemp) {
            this.setState({
                defaultLeaderboard: this.state.defaultLeaderboardTemp
            }, () => {
                this.updateSettings();
            });
        }
        var modalCloseButton = document.getElementById('settingsModalClose');
        modalCloseButton?.click();
    }

    refreshSettingsModal = () => {
        this.setState({
            defaultLeaderboardTemp: this.state.defaultLeaderboard
        });
    }

    updateSettings() {
        localStorage.setItem("defaultLeaderboard", "" + this.state.defaultLeaderboard);
    }

    render() {
        return (
            <Router>
                <Auth0ProviderWithHistory>
                    <Navbar />

                    <ContentContainer>
                        <StatusMessage />
                        <Switch>
                            <Route path="/Leaderboard">
                                <Leaderboard />
                            </Route>
                            <Route path="/Error">
                                <ErrorPage />
                            </Route>
                            <Route path="/Home">
                                <Home />
                            </Route>
                            <Route path="/Challenges/Create">
                                <CreateChallenge />
                            </Route>
                            <Route path="/Challenges/Edit">
                                <EditChallenge />
                            </Route>
                            <Route path="/Challenges">
                                <Challenges />
                            </Route>
                            <Route path="/Users">
                                <Users />
                            </Route>
                            <Route path="/AdminSettings">
                                <AdminSettings />
                            </Route>
                            <Route path="/">
                                {this.state.defaultLeaderboard && 
                                    <Leaderboard />
                                }
                                {!this.state.defaultLeaderboard &&
                                    <Home />
                                }
                            </Route>
                        </Switch>
                    </ContentContainer>
                    
                    <Settings 
                        defaultLeaderboard = {this.state.defaultLeaderboard}
                        defaultLeaderboardTemp = {this.state.defaultLeaderboardTemp}
                        refreshSettingsModal={this.refreshSettingsModal.bind(this)}
                        handleSettingsChange={this.handleSettingsChange.bind(this)}
                        handleSettingsSubmit={this.handleSettingsSubmit.bind(this)}
                        />
                        
                    <ReloadNotification />

                    <Footer />
                </Auth0ProviderWithHistory>
            </Router>
        );
    }
}

export default App;
