import React from "react";
import { Link } from "react-router-dom";
import { AzureFunctionUri, BootstrapCardColors, ChallengesJsonUri, ConnectWithStravaLink, filterOngoingChallenges } from "./Constants";
import ConnectWithStravaImage from "./images/btn_strava_connectwith_orange.png";
import ChallengeModel from "./models/ChallengeModel";
import ChallengesConfigurationModel from "./models/ChallengesConfigurationModel";

type Props = {};
type State = { challenges?: ChallengeModel[]; loadingConfig: boolean; challengesConfiguration?: ChallengesConfigurationModel };
class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            challenges: undefined,
            loadingConfig: true,
            challengesConfiguration: undefined,
        };
    }

    componentDidMount() {
        this.getChallengesJson();
        this.getChallengesConfiguration();
    }

    getChallengesJson() {
        fetch(ChallengesJsonUri, {
            cache: "no-cache",
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Home: " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: ChallengeModel[]) => {
                this.setState({ challenges: filterOngoingChallenges(data) });
            })
            .catch((error) => {
                console.error("Error: ", error);
                this.setState({ challenges: undefined });
            });
    }

    async getChallengesConfiguration() {
        this.setState({ loadingConfig: true }, async () => {
            fetch(AzureFunctionUri + "api/ChallengesConfiguration", {
                cache: "no-cache",
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok. Home (GET: ChallengesConfiguration): " + response.statusText + "(" + response.status + ")"
                        );
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data) {
                        this.setState({ challengesConfiguration: data, loadingConfig: false });
                    } else {
                        this.setState({ challengesConfiguration: undefined, loadingConfig: false });
                    }
                })
                .catch((error) => {
                    console.error("Error: ", error);
                    this.setState({ challengesConfiguration: undefined, loadingConfig: false });
                });
        });
    }

    render() {
        return (
            <div className="container px-0 pt-3">
                <div className="row row-cols-1 row-cols-md-2">
                    <div className="col">
                        <p className="fs-5 fw-bold fst-italic text-center">
                            Don’t limit your challenges, challenge your limits! <br />
                            Be better by doing better, one kilometre at a time.
                        </p>
                        <h4>Welcome to Darvelo Challenges.</h4>
                        <p className="mb-0">
                            Here you will find your next goal on your way to improving your fitness. <br />
                            We offer cycling challenges for riders of all abilities.
                            <br />
                            Each challenge will have a specified target and time for you to complete it. <br />
                            To track your performance, our application will connect to your strava account to fetch the data. <br />
                        </p>
                        <div className="pb-2">
                            <Link to="/Leaderboard">Click here</Link> to view the current leaderboard
                        </div>
                        {!this.state.loadingConfig && (
                            <>
                                {!this.state.challengesConfiguration || this.state.challengesConfiguration.registration ? (
                                    <>
                                        <h4>Register</h4>
                                        <p>
                                            Sign up for the leaderboard by clicking the orange "Connect with Strava" button below!
                                            <br />
                                        </p>
                                        <div className="text-center pb-3">
                                            <a href={ConnectWithStravaLink}>
                                                <img src={ConnectWithStravaImage} alt="Connect with Strava Link" />
                                            </a>
                                        </div>
                                    </>
                                ) : (
                                    <h5>Registration Closed!</h5>
                                )}
                            </>
                        )}
                    </div>
                    {this.state.challenges && (
                        <div className="col">
                            <h3>Ongoing Challenges</h3>
                            {this.state.challenges.map((challenge: ChallengeModel, index: number) => {
                                return (
                                    <div className={"card border-" + BootstrapCardColors[index % BootstrapCardColors.length] + " mb-3"} key={index}>
                                        <div className={"card-header bg-" + BootstrapCardColors[index % BootstrapCardColors.length] + " text-white"}>
                                            {challenge.name}
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6>Start Date: {challenge.start_date}</h6>
                                                    <h6>End Date: {challenge.end_date}</h6>
                                                </div>
                                                <div className="col-4">
                                                    <Link
                                                        to={"/Leaderboard?id=" + challenge.id}
                                                        className={
                                                            "btn btn-" + BootstrapCardColors[index % BootstrapCardColors.length] + " float-end text-white"
                                                        }
                                                    >
                                                        View
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="card-text">
                                                {challenge.description.split("\n").map((c: string, index: number) => {
                                                    if (!c) {
                                                        return <br key={index} />;
                                                    }
                                                    return <div key={index}>{c}</div>;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Home;
