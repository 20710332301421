import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Redirect } from "react-router-dom";
import { AzureFunctionUri } from "./Constants";
import ChallengesConfigurationModel from "./models/ChallengesConfigurationModel";
import WebhookModel from "./models/WebhookModel";

type Props = { auth0: Auth0ContextInterface };
type State = { webhookData?: WebhookModel; loadingWebhook: boolean; challengesConfiguration?: ChallengesConfigurationModel; loadingConfig: boolean };
class AdminSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            webhookData: undefined,
            loadingWebhook: true,
            challengesConfiguration: undefined,
            loadingConfig: true,
        };
    }

    componentDidMount() {
        if (this.props.auth0.isAuthenticated) {
            this.getWebhook();
            this.getChallengesConfiguration();
        }
    }

    componentWillUnmount() {}

    componentDidUpdate(prevProps: Props) {
        if (this.state.loadingWebhook && prevProps.auth0.isLoading && !this.props.auth0.isLoading) {
            if (this.props.auth0.isAuthenticated) {
                this.getWebhook();
                this.getChallengesConfiguration();
            }
        }
    }

    async getWebhook() {
        this.setState({ loadingWebhook: true }, async () => {
            const accessToken = await this.props.auth0.getAccessTokenSilently();
            fetch(AzureFunctionUri + "api/ManageWebhook", {
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok. AdminSettings (GET: ManageWebhook): " + response.statusText + "(" + response.status + ")"
                        );
                    }
                    if (response.status === 204) {
                        return null;
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data) {
                        this.setState({ webhookData: data, loadingWebhook: false });
                    } else {
                        this.setState({ webhookData: undefined, loadingWebhook: false });
                    }
                })
                .catch((error) => {
                    console.error("Error: ", error);
                    this.setState({ webhookData: undefined, loadingWebhook: false });
                });
        });
    }

    async getChallengesConfiguration() {
        this.setState({ loadingConfig: true }, async () => {
            const accessToken = await this.props.auth0.getAccessTokenSilently();
            fetch(AzureFunctionUri + "api/ChallengesConfiguration", {
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok. AdminSettings (GET: ChallengesConfiguration): " + response.statusText + "(" + response.status + ")"
                        );
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data) {
                        this.setState({ challengesConfiguration: data, loadingConfig: false });
                    } else {
                        this.setState({ challengesConfiguration: undefined, loadingConfig: false });
                    }
                })
                .catch((error) => {
                    console.error("Error: ", error);
                    this.setState({ challengesConfiguration: undefined, loadingConfig: false });
                });
        });
    }

    async updateChallengeRegistration(registration: boolean) {
        this.setState({ loadingConfig: true }, async () => {
            const challengeConfigurationJson = { id: this.state.challengesConfiguration?.id, registration: registration } as ChallengesConfigurationModel;
            const accessToken = await this.props.auth0.getAccessTokenSilently();
            fetch(AzureFunctionUri + "api/ChallengesConfiguration", {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(challengeConfigurationJson),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok. AdminSettings (PUT: ChallengesConfiguration): " + response.statusText + "(" + response.status + ")"
                        );
                    }
                    this.getChallengesConfiguration();
                })
                .catch((error) => {
                    console.error(error);
                    this.getChallengesConfiguration();
                });
        });
    }

    async connectWebhook() {
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/ManageWebhook", {
            method: "POST",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. AdminSettings (POST): " + response.statusText + "(" + response.status + ")");
                }
                this.getWebhook();
            })
            .catch((error) => {
                console.error("Error: ", error);
                this.getWebhook();
            });
    }

    async disconnectWebhook() {
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/ManageWebhook", {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. AdminSettings (DELETE): " + response.statusText + "(" + response.status + ")");
                }
                this.getWebhook();
            })
            .catch((error) => {
                console.error("Error: ", error);
                this.getWebhook();
            });
    }

    render() {
        return (
            <div>
                {!this.props.auth0.isAuthenticated && !this.props.auth0.isLoading && (
                    <Redirect to="/Error?error=You are not authorized to view this page. Please log in." />
                )}
                <h1 className="pt-2">Admin Settings</h1>
                {this.props.auth0.isAuthenticated && (
                    <>
                        <div id="main-content">
                            <div className="text-muted pb-1">*Do not Spam the buttons below</div>
                            <div className="row px-1 pb-1">
                                <h3>Webhook Connection to Strava</h3>
                                {this.state.webhookData && (
                                    <>
                                        <div>Webhook ID: {this.state.webhookData.id}</div>
                                        <div>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    this.disconnectWebhook();
                                                }}
                                            >
                                                Disconnect
                                            </button>
                                        </div>
                                    </>
                                )}
                                {!this.state.webhookData && !this.state.loadingWebhook && (
                                    <div>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => {
                                                this.connectWebhook();
                                            }}
                                        >
                                            Connect
                                        </button>
                                    </div>
                                )}
                            </div>
                            <hr />
                            <div className="row px-1 pb-1">
                                <h3>Challenges Configuration</h3>
                                {this.state.challengesConfiguration && (
                                    <>
                                        <div>Registration: {this.state.challengesConfiguration.registration ? "Open" : "Closed"}</div>
                                        {!this.state.challengesConfiguration.registration && (
                                            <div>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => {
                                                        this.updateChallengeRegistration(true);
                                                    }}
                                                >
                                                    Open Registration
                                                </button>
                                            </div>
                                        )}
                                        {this.state.challengesConfiguration.registration && (
                                            <div>
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        this.updateChallengeRegistration(false);
                                                    }}
                                                >
                                                    Close Registration
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default withAuth0(AdminSettings);
