import { withAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { AzureFunctionUri } from "./Constants";

// Date Picker
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import TextField from "@material-ui/core/TextField";
import { ChallengeType } from "./models/ChallengeModel";

type Props = { auth0: any }
type State = { challengeName: string, 
    challengeStartDate: Date, 
    challengeEndDate: Date, 
    challengeType: string, 
    challengeOngoing: boolean, 
    created: boolean, 
    challengeShowMedals: boolean,
    challengeGoldMedal: number,
    challengeSilverMedal: number,
    challengeBronzeMedal: number,
    challengeDescription: string
 }
class CreateChallenge extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            challengeName: "",
            challengeStartDate: new Date(),
            challengeEndDate: new Date(),
            challengeType: ChallengeType.Distance,
            challengeOngoing: false,
            created: false,
            challengeShowMedals: false,
            challengeGoldMedal: 0,
            challengeSilverMedal: 0,
            challengeBronzeMedal: 0,
            challengeDescription: "",
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    async createChallenge() {
        if (this.state.challengeName && this.state.challengeStartDate && this.state.challengeEndDate) {
            const accessToken = await this.props.auth0.getAccessTokenSilently();
            const challengeJson = {
                name: this.state.challengeName ?? "", 
                start_date: this.state.challengeStartDate.toLocaleDateString('en-GB'),
                end_date: this.state.challengeEndDate.toLocaleDateString('en-GB'),
                type: this.state.challengeType ?? "",
                is_ongoing: this.state.challengeOngoing,
                show_medals: this.state.challengeShowMedals,
                gold_medal: this.state.challengeShowMedals ? parseFloat(this.state.challengeGoldMedal.toString()) : 0,
                bronze_medal: this.state.challengeShowMedals ? parseFloat(this.state.challengeBronzeMedal.toString()) : 0,
                silver_medal: this.state.challengeShowMedals ? parseFloat(this.state.challengeSilverMedal.toString()) : 0,
                description: this.state.challengeDescription ?? "",
            }

            fetch(AzureFunctionUri + "api/Challenges", {
                method: "POST",
                cache: "no-cache",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(challengeJson),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. Challenges (POST): ' + response.statusText + "(" + response.status + ")");
                }
                this.setState({created: true});
            }).catch((error) => {
                console.error(error);
                this.setState({created: true});
            });
        }
    }

    render() {
        return (
            <div className="container">
                {!this.props.auth0.isAuthenticated && !this.props.auth0.isLoading && (
                    <Redirect to="/Error?error=You are not authorized to view this page. Please log in." />
                )}
                {this.state.created && (
                    <Redirect to="/Challenges" />
                )}
                <h1 className="pt-3">Create Challenge</h1>
                {this.props.auth0.isAuthenticated && (
                    <>
                        <form>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeNameInput" className="col-4 col-sm-3 col-lg-2">Name: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input type="text" className="form-control" id="challengeNameInput" placeholder="Challenge Name" value={this.state.challengeName} name="challengeName" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeStartDateInput" className="col-4 col-sm-3 col-lg-2">Start Date: </label>
                                <div className="col-8 col-md-6 col-lg-4 my-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={this.state.challengeStartDate}
                                            onChange={(date: Date | null) => {
                                                this.setState({challengeStartDate: date ? date : new Date()});
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true}/>}
                                            disableCloseOnSelect={false}
                                            inputFormat="dd/MM/yyyy"
                                            InputAdornmentProps={{ position: "start" }}
                                            maxDate={this.state.challengeEndDate}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeEndDateInput" className="col-4 col-sm-3 col-lg-2">End Date: </label>
                                <div className="col-8 col-md-6 col-lg-4 my-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={this.state.challengeEndDate}
                                            onChange={(date: Date | null) => {
                                                this.setState({challengeEndDate: date ? date : new Date()});
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth={true} />}
                                            disableCloseOnSelect={false}
                                            inputFormat="dd/MM/yyyy"
                                            InputAdornmentProps={{ position: "start" }}
                                            minDate={this.state.challengeStartDate}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <label className="col-4 col-sm-3 col-lg-2" htmlFor="challengeTypeSelect">
                                    Type: 
                                </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <select className="form-select" aria-label="Select Challenge Type" id="challengeTypeSelect" name="challengeType" value={this.state.challengeType} onChange={this.handleInputChange}>
                                        <option value={ChallengeType.Distance}>{ChallengeType.Distance}</option>
                                        <option value={ChallengeType.ThirtyXThirty}>{ChallengeType.ThirtyXThirty}</option>
                                        <option value={ChallengeType.LongestDistance}>{ChallengeType.LongestDistance}</option>
                                        <option value={ChallengeType.Elevation}>{ChallengeType.Elevation}</option>
                                        <option value={ChallengeType.Time}>{ChallengeType.Time}</option>
                                        <option value={ChallengeType.Darvelo100}>{ChallengeType.Darvelo100}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row my-2 align-items-center">
                                <div className="col-4 col-sm-3 col-lg-2">Is Ongoing? </div>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-check-input" type="checkbox" checked={this.state.challengeOngoing} id="challengeOngoingCheck" name="challengeOngoing" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="row my-2 align-items-center">
                                <div className="col-4 col-sm-3 col-lg-2">Show Medals? </div>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-check-input" type="checkbox" checked={this.state.challengeShowMedals} id="challengeShowMedals" name="challengeShowMedals" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeGoldMedal" className="col-4 col-sm-3 col-lg-2">Gold Medal: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-control" type="number" min={0} id="challengeGoldMedal" name="challengeGoldMedal" aria-describedby="challengeGoldMedal" value={this.state.challengeGoldMedal} onChange={this.handleInputChange} disabled={!this.state.challengeShowMedals}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeSilverMedal" className="col-4 col-sm-3 col-lg-2">Silver Medal: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-control" type="number" min={0} id="challengeSilverMedal" name="challengeSilverMedal" aria-describedby="challengeSilverMedal" value={this.state.challengeSilverMedal} onChange={this.handleInputChange} disabled={!this.state.challengeShowMedals}/>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-center">
                                <label htmlFor="challengeBronzeMedal" className="col-4 col-sm-3 col-lg-2">Bronze Medal: </label>
                                <div className="col-8 col-md-6 col-lg-4">
                                    <input className="form-control" type="number" min={0} id="challengeBronzeMedal" name="challengeBronzeMedal" aria-describedby="challengeBronzeMedal" value={this.state.challengeBronzeMedal} onChange={this.handleInputChange} disabled={!this.state.challengeShowMedals}/>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="challengeDescription" className="form-label">Challenge Description</label>
                                <textarea className="form-control" id="challengeDescription" name="challengeDescription" aria-describedby="challengeDescription" value={this.state.challengeDescription} onChange={this.handleInputChange} rows={3}></textarea>
                            </div>
                        </form>
                        <button className="btn btn-success my-2" onClick={() => {this.createChallenge()}}>Create</button>
                    </>
                )}
                <div className="d-flex flex-row-reverse">
                    <Link to="/Challenges" className="btn btn-primary">Go Back</Link>
                </div>
            </div>
        );
    }
}

export default withAuth0(CreateChallenge);