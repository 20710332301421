import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {History, LocationState} from 'history';

type Props = { query: URLSearchParams, history: History<LocationState> };
type State = { message: string };
class StatusMessage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            message: "",
        }
    }

    componentDidMount() {
        if (this.props.query.has("stravaSuccess")) {
            var successMessage = this.props.query.get("stravaSuccess");
            if(successMessage) this.setState({message: successMessage}, () => {
                this.props.query.delete("stravaSuccess");
                this.props.history.push({search: this.props.query.toString()});
            });
        }
    }

    render() {
        return (
            <>
                {this.state.message && (
                    <>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>
                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </symbol>
                    </svg>
                    <div className="alert alert-success alert-dismissible d-flex align-items-center fade show mt-2 mb-0" role="alert">
                        <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill"/></svg>
                        <div>{this.state.message}</div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    </>
                )}
            </>
        );
    }
}

export default injectQuery(StatusMessage);

function injectQuery (Comp: typeof React.Component) {
    const InjectedQuery = function (props: any) {
        const query = useQuery();
        const history = useHistory();
        return <Comp {...props} query={query} history={history} />;
    };
    return InjectedQuery;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}