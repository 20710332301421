import { withAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthButtons from './AuthButtons';

type Props = { auth0: any }
type State = {}
class Navbar extends React.Component<Props, State> {
    closeCollapseNavbar() {
        var collapse = document.getElementById('navbar-collapse');
        if (collapse !== null && collapse.hasAttribute("class"))
        {
            var collapseClass = collapse.getAttribute("class");
            if (collapseClass !== null && collapseClass.includes("show")) {
                document.getElementById('navbar-toggler')?.click();
            }
        };
    }

    render() {
        const { isAuthenticated } = this.props.auth0;
        return (
            <header>
                <nav className="navbar navbar-expand-md navbar-toggleable-md navbar-light bg-white border-bottom box-shadow">
                    <div className="container-lg">
                        <Link className="navbar-brand" to="/" onClick={() => this.closeCollapseNavbar()}>Darvelo Challenges</Link>
                        <button className="navbar-toggler" id="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse collapse d-md-inline-flex justify-content-between" id="navbar-collapse">
                            <ul className="navbar-nav flex-grow-1">
                                <li className="nav-item">
                                    <Link to="/Home" className="nav-link text-dark" onClick={() => this.closeCollapseNavbar()}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/Leaderboard" className="nav-link text-dark" onClick={() => this.closeCollapseNavbar()}>Leaderboard</Link>
                                </li>
                                {isAuthenticated && (
                                    <>
                                    <li className="nav-item">
                                        <Link to="/Challenges" className="nav-link text-dark" onClick={() => this.closeCollapseNavbar()}>Challenges</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/Users" className="nav-link text-dark" onClick={() => this.closeCollapseNavbar()}>Users</Link>
                                    </li>
                                    </>
                                )}
                            </ul>
                            <ul className="navbar-nav">
                                <li className="nav-item d-grid gap-2">
                                    <button className="nav-link text-white btn btn-secondary ml-md-2 mt-1 mt-md-0" data-bs-toggle="modal" data-bs-target="#settingsModal">
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft: 10}} width="16" height="16" fill="currentColor" className="bi bi-gear-fill align-middle" viewBox="0 0 16 16">
                                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"></path>
                                        </svg>
                                        <span className="align-middle" style={{marginLeft: 10, marginRight: 10}}>Settings</span>
                                    </button>
                                </li>
                                <AuthButtons />
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default withAuth0(Navbar);