import React from 'react';
import PwrdByStravaLogo from './images/api_logo_pwrdBy_strava_stack_light.png';

class Footer extends React.Component {
    render() {
        return (
            <footer className="border-top footer text-muted mt-auto">
                <div className="container">
                    <div className="d-sm-flex align-items-center">
                        <div className="me-auto">
                            <span className="text-break">
                                &copy; 2021 - Darvelo Challenges - Website by Mohamed Nathoo
                            </span>
                        </div>
                        <div className="float-end">
                            <a href="https://www.strava.com/" target="_blank" rel="noreferrer">
                                <img src={PwrdByStravaLogo} className="img-fluid" style={{height: "auto", maxHeight: "50px"}} alt="PwrdByStravaLogo" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;